<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="invite-log-layout">
      <div class="il-page-header">
        <img src="@/assets/common/goBack.png" style="width: 24px;float: left;" @click="goBack()" />
        <div style="float: right;background-color: rgba(220, 215, 227, 1);padding: 2px 6px 3px 6px;border-radius: 20px;">
          <img src="@/assets/common/goldIcon.png" style="width: 14px;vertical-align: middle;" />
          <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-left: 4px;margin-right: 8px;"> {{goldNum}}</span>
          <img src="@/assets/common/plusSign.png" @click="go()" style="width: 10px;vertical-align: middle;" />
        </div>
      </div>
      <div style="">
        <el-tabs type="border-card">
          <el-tab-pane :label="$t('inviteFriend.i_newest')">
            <div style="overflow-y: scroll;height: 81.5vh;">
              <div v-infinite-scroll="loadMoreOne" infinite-scroll-disabled="busyOne" infinite-scroll-distance="10">
                <div v-for="log in inviteLogOne" :key="log.id">
                  <div style="background-color: rgba(153, 176, 186, 1);margin-bottom: 3%;padding: 4%;border-radius: 8px;color: white;">
                    <div style="overflow: hidden;">
                      <div style="float: left;text-align: left;">
                        <span>{{$t('inviteFriend.i_friends')}}ID：{{log.beInviteUid}}</span>
                        <div style="margin-top: 10%;">
                          {{log.inviteTime}}
                        </div>
                      </div>
                      <div style="float: right;margin-top: 5%;font-size: 18px;">
                        <div style="float: left;margin-top: 3%;margin-right: 4px;">+{{log.reward}}</div>
                        <img src="@/assets/common/goldIcon.png" style="width: 20px;" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="inviteLogOne==''" style="text-align: center;">
                  <img width="150" src="@/assets/common/noIcon.png" style="margin-top: 30%;" />
                  <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane :label="$t('inviteFriend.i_biggest')">
            <div style="overflow-y: scroll;height: 81.5vh;">
              <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                <div v-for="log in inviteLog" :key="log.id">
                  <div style="background-color: rgba(153, 176, 186, 1);margin-bottom: 3%;padding: 4%;border-radius: 8px;color: white;">
                    <div style="overflow: hidden;">
                      <div style="float: left;text-align: left;">
                        <span>{{$t('inviteFriend.i_friends')}}ID：{{log.beInviteUid}}</span>
                        <div style="margin-top: 10%;">
                          {{log.inviteTime}}
                        </div>
                      </div>
                      <div style="float: right;margin-top: 5%;font-size: 18px;">
                        <div style="float: left;margin-top: 3%;margin-right: 4px;">+{{log.reward}}</div>
                        <img src="@/assets/common/goldIcon.png" style="width: 20px;" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="inviteLog==''" style="text-align: center;">
                <img width="150" src="@/assets/common/noIcon.png" style="margin-top: 30%;" />
                <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
              </div>
            </div>
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ inviteLogParam.failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        goldNum: 0,
        checkDialog: false,
        inviteLogOne: [],
        busyOne: false,
        pageNumOne: 0,
        isLastOne: false,
        inviteLog: [],
        busy: false,
        pageNum: 0,
        isLast: false,
        inviteLogParam: {
          check: "",
          failMessage: "",
          appScheme: ""
        }
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      go() {
        if (this.inviteLogParam.check == "login") {
          window.location.href = this.inviteLogParam.appScheme + "login";
        } else {
          window.location.href = localStorage.getItem("appScheme");
        }
      },
      loadMoreOne: function () {
        if (this.isLastOne != true) {
          this.busyOne = true;
          this.pageNumOne++;

          this.isLoading = true;
          this.$axios({
            url: "/apps/giftWeb/web/inviteLog",
            method: "get",
            params: {
              pageNum: this.pageNumOne,
              status: 1
            }
          }).then((result) => {
            this.isLoading = false;
            if (result.data.code == 1) {
              this.goldNum = result.data.data.goldNum;
              result.data.data.inviteLog.forEach((element) => {
                this.inviteLogOne.push(element);
              });
              this.isLastOne = result.data.data.isLast;
            } else if (result.data.code == -2) {
              this.inviteLogParam.check = result.data.data.check;
              this.inviteLogParam.failMessage = result.data.message;
              this.inviteLogParam.appScheme = result.data.data.appScheme;
              this.checkDialog = true;
            }
          });

          this.busyOne = false;
        }
      }
      // loadMore: function () {
      //   if (this.isLast != true) {
      //     this.busy = true;
      //     this.pageNum++;

      //     this.isLoading = true;
      //     this.$axios({
      //       url: "/webGift/inviteLog",
      //       method: "get",
      //       params: {
      //         pageNum: this.pageNum,
      //         status: 0
      //       }
      //     }).then((result) => {
      //       this.isLoading = false;
      //       if (result.data.code == 1) {
      //         this.$store.state.goldNum = result.data.data.goldNum;
      //         result.data.data.inviteLog.forEach((element) => {
      //           this.inviteLog.push(element);
      //         });
      //         this.isLast = result.data.data.isLast;
      //       }
      //     });
      //     this.busy = false;
      //   }
      // }
    },
    created() {}
  };
</script>

<style>
  #invite-log-layout {
    width: 100%;
    height: 100%;
  }
  #invite-log-layout .il-page-header {
    padding: 4%;
    overflow: hidden;
  }
  #invite-log-layout .is-top {
    background: white;
  }
  #invite-log-layout .el-tabs__content {
    background: rgba(153, 176, 186, 0.5) !important;
  }
  #invite-log-layout .el-tabs__nav .is-top {
    background: rgba(222, 231, 233, 1);
    color: black;
    border: none;
  }
  #invite-log-layout .is-active {
    background: rgba(153, 176, 186, 0.5) !important;
    color: black !important;
    border: none;
  }
  #invite-log-layout .el-drawer {
    border-radius: 20px 20px 0 0 !important;
  }
  #invite-log-layout .el-tabs--border-card {
    border: none !important;
    box-shadow: none !important;
  }
</style>
