<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {};
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    width: 100%;
    height: 100%;
  }
  html {
    width: 100%;
    height: 100%;
    background-color: white;
  }
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  .el-loading-spinner .path {
    stroke: white !important;
  }
  .el-loading-spinner .el-loading-text {
    color: white !important;
  }
  .el-page-header__left {
    margin-right: 16px !important;
  }
  .el-page-header__left::after {
    right: -8px !important;
  }
  .el-icon-back:before,
  .el-page-header__title {
    font-weight: bold !important;
  }
  .el-dialog {
    border-radius: 15px !important;
  }
  .el-dialog__header {
    background: white !important;
    border-radius: 15px 15px 0 0 !important;
  }
  .el-dialog__body {
    background: white !important;
    border-radius: 0 0 15px 15px !important;
    padding-top: 10px !important;
  }
</style>
