const en = {
  common: {
    i_loading: "Loading...",
    i_back: "Back",
    i_timedOut: "Request timed out, please check network",
    i_copy: "Copied",
    i_cancel: "Cancel",
    i_goBack: "Goback now",
    i_copyC: "Copy"
  },
  index: {
    i_withdraw: "Withdraw",
    i_cashDraw: "Cash Spin",
    i_language: "en"
  },
  convert: {
    i_convert: "Withdraw",
    i_convertLog: "Record",
    i_convertT: "Convert your coins to cash",
    i_choose: "Please choose a redemption method",
    i_input: "Input",
    i_account: "Receiving account",
    i_editAccount: "Edit account",
    i_checkN: "No responsibility for wrong numbers",
    i_receivingName: "Receiving name",
    i_enterReceivingName: "Enter receiving name",
    i_select: "Select currency",
    i_currency: "Currency",
    i_pleaseSelect: "Please select",
    i_bindAccount: "Bind the Receiving account",
    i_enterReceivingAccount: "Enter receiving account",
    i_redeemNow: "Withdraw now",
    i_bindN: "After binding, it cannot be changed",
    i_bindNow: "Bind now",
    i_following: "The following",
    i_bindT:
      "The account is about to be bound to your account. It cannot be changed after binding. Are you sure?",
    i_bind: "Bind",
    i_changeNow: "Change now",
    i_changeT:
      "Modifications need to be reviewed by the administrator, and cannot be exchanged during the review period. Do you want to confirm the change?",
    i_newReceivingAccount: "New receiving account",
    i_modifyReceivingAccount: "Modify the receiving account",
    i_newReceivingName: "New receiving name",
    i_confirm: "Confirm",
    i_ok: "Ok",
    i_convertMsg: "Redemption has been submitted and is under review",
    i_info_ing: "Information under review",
    i_infoN: "Information review failed",
    i_tips: "Tips",
    i_activateMsg: "Please activate Premium Features",
    i_accountN: "Account cannot be empty",
    i_nameN: "Name cannot be empty",
    i_reviewSucceeded: "Review succeeded",
    i_underReview: "Under review",
    i_auditFailure: "Audit failure",
    i_invalid: "Invalid",
    i_remark: "Remark",
    i_checkConvertInfo: "Please confirm your info"
  },
  inviteFriend: {
    i_inviteFriend: "Invite friends",
    i_inviteT: "Every friend you invite",
    i_reward: "Reward",
    i_commissionT: "Friends' coins",
    i_total: "Total",
    i_friends: "Friends ",
    i_text1: "Friends download app via your link",
    i_text2: "Friends login/enter your code",
    i_text3_1: "Friends Play Game -> you get ",
    i_text3_2: " friends' coins",
    i_code: "Invitation code",
    i_inviteNow: "Invite now",
    i_enterCode: "Enter the code",
    i_friendCode: "Fill in friend's Code",
    i_code1: "Only 1 invitation code can be filled in",
    i_claimNow: "Claim it now",
    i_inviteLog: "Invitation record",
    i_notActivated: "Premium features are not activated",
    i_filledIn: "Filled in",
    i_noLog: "No content yet",
    i_newest: "Newest",
    i_biggest: "Biggest",
    i_tipsDialogMsg:
      "Your friends need to download the app by clicking your link to display the Withdraw entrance !!!"
  },
  invite: {
    inviteImg: "en",
    i_havaDownloaded: "Have you downloaded",
    i_downloaded: "Downloaded",
    i_goDownload: "Go Download"
  },
  errorPage: {
    i_errorMessage:
      "Authorization expired, please return to the app to reopen the page",
    i_goNow: "Go now",
    i_giftEnd: "The Gift event has ended !!!"
  },
  test: {
    i_selectExchangeAmount: "Select exchange amount",
    i_pleaseSelectAnExchangeItem: "Please select an exchange item",
    i_pleaseSelectAGiftCard: "Please select a gift card",
    i_inviteFriends: "Invite Friends",
    i_inviteFriendsToGetMoreCash: "Invite friends to get more cash",
    i_inviteNow: "Invite now",
    i_spinNow: "Spin Now",
    i_rewardFor: "Reward for",
    i_everyFriends: "Every Friends",
    i_yourFriends: "Your Friends",
    i_coinsReward: "Coins Reward",
    i_onlyOnce: "only once",
    i_confirmFriends: "Submit",
    i_inviteRules: "Invite Rules",
    i_yourFriendsDownloadApp: "Your friends download app",
    i_acitveGiftCard: "Acitve Gift Card",
    i_enterInvitationCode: "Enter invitation code",
    i_giftCodeEvent: "Gift code event",
    i_getMoreCoins: "Get more coins",
  },
  giftCode: {
    i_step1: "Copy gift code and open APP",
    i_step2: "Click gift card",
    i_step3: "Enter gift code event page",
    i_step4: "Paste gift code and click redeem",
    i_openApp: "Open APP",
    i_giftCode: "Gift Code",
    i_countdown: "Gift Code Countdown",
    i_giftCodeSoldOut: "You are late, gift codes are sold out",
    i_getItNow: "Get it now",
    i_soldOut: "Sold out",
    i_remaining: "Remaining",
    i_aboutToStart: "Wait to begin",
    i_yourGiftCode: "Your Gift Code",
    i_redeemGiftCode: "Redeem Gift Code",
    i_goExchange: "Go exchange",
    i_congratulations: "Congratulations",
    i_copyAndGoTutorial: "Copy and go tutorial",
    i_enterGiftReceiveCoins: "Enter gift code to receive the Coins",
    i_enterGiftCode: "Enter gift code",
    i_confirm: "Confirm",
    i_joinCommunityGetGiftCode: "Join our community, Get gift code",
    i_joinNow: "Join now",
    i_successfulExchange: "Successful exchange",
    i_coins: "Coins",
    i_ok: "Ok",
    i_selectCommunity: "Select a community",
    i_beCountdownEnd: "Will be issued after the countdown ends",
    i_giftCodesBeingIssued: "Gift codes are being issued",
    i_pleaseWaitNextGiftCodes: "Please wait for the next batch of gift codes"
  },
  language: {
    zh: "中文",
    en: "English"
  }
};
export default en;
