const zh = {
  common: {
    i_loading: "拼命加载中。。。",
    i_back: "返回",
    i_timedOut: "请求超时，请检查网络",
    i_copy: "复制成功",
    i_cancel: "取消",
    i_goBack: "立即返回",
    i_copyC: "复制"
  },
  index: {
    i_withdraw: "立即兑换",
    i_cashDraw: "开始抽奖",
    i_language: "zh"
  },
  convert: {
    i_convert: "兑换",
    i_convertLog: "兑换记录",
    i_convertT: "将你的金币换成现金",
    i_choose: "请选择兑换方式",
    i_input: "输入",
    i_account: "收款账号",
    i_editAccount: "修改账号",
    i_checkN: "输错号码概不负责",
    i_receivingName: "收款姓名",
    i_enterReceivingName: "输入收款姓名",
    i_select: "选择兑换货币",
    i_currency: "兑换货币",
    i_pleaseSelect: "请选择",
    i_bindAccount: "绑定收款账号",
    i_enterReceivingAccount: "输入收款账号",
    i_redeemNow: "立即兑换",
    i_bindN: "绑定后不可更改",
    i_bindNow: "立即绑定",
    i_following: "以下",
    i_bindT: "账户即将绑定为你的收款账户，绑定后不可更改，确定绑定吗？",
    i_bind: "确定绑定",
    i_changeNow: "立即更改",
    i_changeT:
      "申请修改需要等待管理员审核，审核期间将不能进行兑换，确认更改吗？",
    i_newReceivingAccount: "新的收款账号",
    i_modifyReceivingAccount: "修改收款账号",
    i_newReceivingName: "新的收款姓名",
    i_confirm: "确定更改",
    i_ok: "好的",
    i_convertMsg: "兑换申请已经提交，正在审核中",
    i_info_ing: "信息审核中",
    i_infoN: "信息审核失败",
    i_tips: "提示",
    i_activateMsg: "请先激活高级功能",
    i_accountN: "账号不能为空",
    i_nameN: "姓名不能为空",
    i_reviewSucceeded: "审核成功",
    i_underReview: "审核中",
    i_auditFailure: "审核失败",
    i_invalid: "无效",
    i_remark: "备注",
    i_checkConvertInfo: "请确认你的兑换信息"
  },
  inviteFriend: {
    i_inviteFriend: "邀请好友",
    i_inviteT: "每邀请一个好友",
    i_reward: "奖励",
    i_commissionT: "好友的提成",
    i_total: "总共",
    i_friends: "好友",
    i_text1: "好友通过你的链接下载APP",
    i_text2: "好友在APP内登陆/输入你的邀请码",
    i_text3_1: "好友玩游戏 -> 你获得",
    i_text3_2: "好友的金币",
    i_code: "邀请码",
    i_inviteNow: "立即邀请",
    i_enterCode: "填写邀请码",
    i_friendCode: "填写好友邀请码",
    i_code1: "只能填写1位好友邀请码",
    i_claimNow: "立即领取",
    i_inviteLog: "邀请记录",
    i_notActivated: "未升级宝箱",
    i_filledIn: "已填写",
    i_noLog: "暂无内容",
    i_newest: "最新",
    i_biggest: "最大",
    i_tipsDialogMsg: "你的好友需要通过点击你的链接下载app才能显示兑换入口 !!!"
  },
  invite: {
    inviteImg: "zh",
    i_havaDownloaded: "是否已经下载了",
    i_downloaded: "已经下载",
    i_goDownload: "立即下载"
  },
  errorPage: {
    i_errorMessage: "授权过期，请返回app重新打开该页面",
    i_goNow: "立即返回",
    i_giftEnd: "该礼品活动已经结束!"
  },
  test: {
    i_selectExchangeAmount: "选择兑换金额",
    i_pleaseSelectAnExchangeItem: "请选择一个兑换项",
    i_pleaseSelectAGiftCard: "选择你需要的礼品卡",
    i_inviteFriends: "邀请好友",
    i_inviteFriendsToGetMoreCash: "邀请好友获取更多现金",
    i_inviteNow: "立即邀请",
    i_spinNow: "立即抽奖",
    i_rewardFor: "每邀请",
    i_everyFriends: "一个好友",
    i_yourFriends: "你的好友",
    i_coinsReward: "金币奖励",
    i_onlyOnce: "仅一次",
    i_confirmFriends: "确定好友",
    i_inviteRules: "邀请规则",
    i_yourFriendsDownloadApp: "好友下载APP",
    i_acitveGiftCard: "激活礼品卡",
    i_enterInvitationCode: "输入邀请码",
    i_giftCodeEvent: "礼品码活动",
    i_getMoreCoins: "获取更多金币",
  },
  giftCode: {
    i_step1: "複製禮品碼，打開APP",
    i_step2: "點擊禮品卡",
    i_step3: "進入禮品碼活動頁面",
    i_step4: "粘貼你的禮品碼,點擊兌換",
    i_openApp: "打開APP",
    i_giftCode: "礼品码",
    i_countdown: "发放倒计时",
    i_giftCodeSoldOut: "礼品码已抢完了",
    i_getItNow: "立即开抢",
    i_soldOut: "已抢完",
    i_remaining: "剩余",
    i_aboutToStart: "即将开始",
    i_yourGiftCode: "你的礼品码",
    i_redeemGiftCode: "兑换礼品码",
    i_goExchange: "前往兑换",
    i_congratulations: "恭喜抢到礼品码",
    i_copyAndGoTutorial: "复制并打开教程",
    i_enterGiftReceiveCoins: "输入礼品码，领取好礼",
    i_enterGiftCode: "输入礼品码",
    i_confirm: "确认兑换",
    i_joinCommunityGetGiftCode: "进群，抢礼品码",
    i_joinNow: "立即加入",
    i_successfulExchange: "兑换成功",
    i_coins: "金币",
    i_ok: "好的",
    i_selectCommunity: "选择你的社群",
    i_beCountdownEnd: "将于倒计时结束后发放",
    i_giftCodesBeingIssued: "礼品码发放中",
    i_pleaseWaitNextGiftCodes: "请等待下一批礼品码"
  },
  language: {
    zh: "中文",
    en: "English"
  }
};
export default zh;
