<template>
  <div id="error-page-layout">
    <div class="error-page-box">
      <div style="text-align: left;">
        <img @click="goApp()" width="30" src="@/assets/images/goBack.png" />
      </div>
      <div style="margin-top: 2%;">
        <div style="background-color: white;padding: 3%;border-radius: 10px;height: 84vh;">
          <img style="width: 120px;margin-top: 30%;" src="@/assets/images/tip.png" />
          <div style="line-height: 30px;margin-top: 10%;">
            {{$t('errorPage.i_errorMessage')}}
          </div>
          <el-button @click="goApp()" type="primary" round style="width: 60%;margin-top: 18%;background-color: rgba(55, 87, 228, 1);" size="large">{{$t('errorPage.i_goNow')}}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    methods: {
      goApp() {
        location.href = "luckygamewallpaper://";
      }
    }
  };
</script>

<style>
  #error-page-layout {
    width: 100%;
    height: 100%;
    background-image: url("@/assets/images/taskCenterBg.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }
  #error-page-layout .error-page-box {
    padding: 4%;
  }
</style>
